import React, { Component } from 'react'
import axios from 'axios';
import { Grid, Container, Header, Segment, Image, GridColumn, Modal, Form, Divider, Button } from 'semantic-ui-react'
import logo from './logo.png';
import u3Image from './3U.png';
import u5Image from './5U.png';
import u10Image from './10U.png';
import steakerLogo from './Steaker_logo.png';
import './App.css'

class App extends Component {
  state = {}

  handleChange = (e, { name, value }) => this.setState({ [name]: value })
  handleSubmit = () => {
    const { steakerId } = this.state // TODO: used in URL to request
    axios.get(`https://campaign-api.steaker.com/${steakerId}`).then(res => {
      const { amount } = res.data;
      this.setState({ steakerId: '', openModal: true, amount: amount })
    })
  }

  render() {
    const { steakerId, openModal, amount } = this.state

    let imageContent = null

    if (amount === 3) {
      imageContent = u3Image
    } else if (amount === 5) {
      imageContent = u5Image
    } else if (amount === 10) {
      imageContent = u10Image
    }

    return (
      <Container >
        <Grid id="my-container" container verticalAlign='middle' columns={1} centered>
          <Grid.Row verticalAlign='middle'>
            <GridColumn id='envelope-col'>
              <Segment id='envelope-segment' inverted color='red'>
                <Image id='envelope-image' src={logo} centered />
                <Header id='header-1' size='huge' textAlign='center' inverted>
                  新年紅包大放送
                </Header>
                <Header id='header-2' size='medium' textAlign='center' inverted>
                    Lunar New Year<br/>Red Envelope Lucky Draw
                </Header>
                <Form id='envelope-form' centered onSubmit={this.handleSubmit}>
                  <Form.Input
                    placeholder='Enter your Steaker ID to try your luck'
                    name='steakerId'
                    value={steakerId}
                    onChange={this.handleChange}
                  />
                  <Form.Button id='submit-button' fluid content='Submit' />
                </Form>
      
                <Divider inverted />
                <Header as='h4' textAlign='center' inverted>
                  參與條件 Eligibility Requirement<p></p>
                  
                  <Header.Subheader>
                    2021/10/24~2022/1/24 (UTC+8) 期間，方案申購金額超過 3,000 USDⓢ 的用戶<p></p>
                    User who has invested over 3,000 USDⓢ at Steaker during 2021/10/24 ~ 2022/1/24 (UTC+8)
                  </Header.Subheader>
                </Header>
              </Segment>
            </GridColumn>
          </Grid.Row>
        </Grid>
        <Modal
          size='mini'
          open={openModal}
          onClose={() => this.setState({ steakerId: '', openModal: false, amount: null })}
        >
          <Modal.Content>
            {amount ? 
            (<>
              <Image size='medium' src={imageContent} centered />
              <Container textAlign='center'>
                <div className='ui' id="congrats-title">恭喜獲得</div>
                <div className='ui' id="congrats-content">{amount} USDT</div>
                <div className='ui' id="congrats-footer">Congrats!<br />You’ve won {amount} USDT!</div>
              </Container>
              <Divider />
              <Header as='h4' textAlign='center'>
                獎金將於 2022/1/27 18:00 前 發送至個人 Steaker 帳戶<p></p>
                The rewards will be issued before 2022/1/27 at 18: 00 (UTC+8)
              </Header>
              <Image size='small' src={steakerLogo} centered />
              </>
            ) :
            (<>
              <Container textAlign='center'>
                <div className='ui' id="congrats-emoji">😢</div>
                <div className='ui' id="congrats-title">很抱歉...</div>
                <div className='ui' id="congrats-content">不符合資格</div>
                <div className='ui' id="congrats-footer">Oops...You’re not eligible.</div>
              </Container>
            </>
            )}
          </Modal.Content>
          
          <Modal.Actions>
              <Button color='black' onClick={() => this.setState({ steakerId: '', openModal: false, amount: null })}>
                關閉
              </Button>
            </Modal.Actions>
        </Modal>
      
      </Container>
    ); 
  }
}

export default App;
